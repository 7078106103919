import { haveCommonItems } from '@utils/index';
import { INTERFACE_NAME } from './constants';

export const sendMessage = (type, payload = {}) => {
    const message = JSON.stringify({ type, payload });

    if (window.webkit) {
        window.webkit.messageHandlers[INTERFACE_NAME].postMessage(message);
    } else {
        window[INTERFACE_NAME]?.postMessage(message, '*');
    }
};

export const isInMobileWebView = () => {
    if (window.webkit?.messageHandlers[INTERFACE_NAME]?.postMessage) {
        return true;
    }

    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isAndroid = /Android/.test(userAgent);

    if (isAndroid) {
        return userAgent.includes('wv') || !userAgent.includes('Chrome');
    }

    if (window.Android) {
        return true;
    }

    return false;
};

export const arePrerequisitesMet = (answers = []) => question => {
    if (!question.prerequisiteQuestion) {
        return true;
    }

    const prerequisiteQuestionAnswer = answers.find(answer => answer.key === question.prerequisiteQuestion)?.values;

    return haveCommonItems(question.prerequisiteValues, prerequisiteQuestionAnswer);
};
