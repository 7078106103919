import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LOCALE } from '@constants';
import { configSelector, externalClientSelector, isAuthSelector } from '@services/auth/selectors';
import { changeLocaleRequest } from '@services/language/actions';
import { selectLanguage } from '@services/language/selectors';
import { getLocale, setMomentLocale } from '@utils/date';
import { getUserLocale } from '@utils/locale';
import { getItem, setItem } from '@utils/storageUtils';

export const useLocale = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const config = useSelector(configSelector);
    const { user } = useSelector(externalClientSelector);
    const isAuth = useSelector(isAuthSelector);
    const language = useSelector(selectLanguage);
    const query = new URLSearchParams(history.location.search);
    const lang = query.get('lc')?.replace('_', '-');
    const locale = getItem(LOCALE);

    useEffect(() => {
        if (isAuth || user) {
            return;
        }

        if (lang) {
            const newLocale = getLocale(lang);
            dispatch(changeLocaleRequest({ locale: newLocale }));
            return;
        }

        const userLocale = getUserLocale(config?.supportedLocales);
        const newLocale = getLocale(userLocale);
        dispatch(changeLocaleRequest({ locale: newLocale }));
    }, [locale, isAuth, config]);

    useEffect(() => {
        if (language !== locale) {
            setItem([LOCALE], [language]);
        }
        setMomentLocale(language);
    }, [language, locale]);
};
