export const INTERFACE_NAME = 'parent';

export const TIME_OUT = 15000;

export const MESSAGE_TYPES = {
    MOUNTED: 'MOUNTED',
    UNMOUNTED: 'UNMOUNTED',
    TIMED_OUT: 'TIMED_OUT',
    ERROR_OCCURED: 'ERROR_OCCURED',
    AUTHENTICATION_DONE: 'AUTHENTICATION_DONE',
    SURVEY_LOADED: 'SURVEY_LOADED',
    SURVEY_SUBMITTED: 'SURVEY_SUBMITTED',
};
